import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import "./background.component.scss";

export const BackgroundComponent = () => {
    
  return (
    <>
      {/* <div classNameName="background">
        <div classNameName="cube"></div>
        <div classNameName="cube"></div>
        <div classNameName="cube"></div>
        <div classNameName="cube"></div>
        <div classNameName="cube"></div>
        <div classNameName="cube"></div>
      </div> */}
{/* 
      <div classNameName="bg"></div>

      <div classNameName="star-field">
      <div classNameName="layer"></div>
      <div classNameName="layer"></div>
      <div classNameName="layer"></div>
      </div> */}

    <div className="backwrap gradient">
      <div className="back-shapes">
          {/* {props.children} */}
          <span className="floating circle element-1"></span>
          <span className="floating triangle element-2"></span>
          <span className="floating cross element-3"></span>
          <span className="floating square element-4"></span>
          <span className="floating square element-5"></span>
          <span className="floating square element-6"></span>
          <span className="floating cross element-7"></span>
          <span className="floating cross element-8"></span>
          <span className="floating circle element-9"></span>
          <span className="floating circle element-10"></span>
          <span className="floating cross element-11"></span>
          <span className="floating cross element-12"></span>
          <span className="floating cross element-13"></span>
          <span className="floating cross element-14"></span>
          <span className="floating square element-15"></span>
          <span className="floating circle element-16"></span>
          <span className="floating triangle element-17"></span>
          <span className="floating triangle element-18"></span>
          <span className="floating triangle element-19"></span>
          <span className="floating triangle element-20"></span>
          <span className="floating triangle element-21"></span>
          <span className="floating cross element-22"></span>
          <span className="floating cross element-23"></span>
          <span className="floating cross element-24"></span>
          <span className="floating cross element-25"></span>
          <span className="floating square element-26"></span>
          <span className="floating square element-27"></span>
          <span className="floating square element-28"></span>
          <span className="floating square element-29"></span>
          <span className="floating square element-30"></span>
          <span className="floating square element-31"></span>
          <span className="floating square element-32"></span>
      </div>
    </div>


    </>
  );
};
