import { Link } from "gatsby";
import React from "react";
// import { OutboundLink } from "gatsby-plugin-google-gtag";
// import LogoWhiteSVG from "../../svg/logoWhiteSVG.svg";
// import LogoPurpleSVG from "../../svg/logoPurpleSVG.svg";
import "./footer.component.scss";

import { Twitter, Instagram, Facebook, Linkedin, GitHub } from "react-feather";
import { StaticImage } from "gatsby-plugin-image";

const FooterComponent = ({ className,  siteTitle }: {className:string; siteTitle: string}) => {
  return (
    <footer className={className}>

        <div className="footer-container">
            <div className="footer-icons-container ">                
                <ul className="footer-icons">
                    <li>
                        {/* <OutboundLink href="https://www.fb.com/Onixdev" target="_blank">
                        <Facebook />
                        </OutboundLink> */}
                        <a href="https://www.fb.com/Devisinggames" target="_blank">
                        <Facebook className="footer-icon"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/devisinggames" target="_blank">
                        <Instagram className="footer-icon"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.twitter.com/devisinggames" target="_blank">
                        <Twitter className="footer-icon"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/company/devisinggames" target="_blank">
                        <Linkedin className="footer-icon"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://github.com/devisinggames" target="_blank">
                        <GitHub className="footer-icon"/>
                        </a>
                    </li>
                </ul>
            </div>

            <div className="footer-content">
                <div className="footer-logo-container">
                    <Link to="/" className="logo-container">
                        <div className="logo-image">
                            <StaticImage
                                className="transition-opacity"
                                src="../../assets/images/logo.png"
                                alt=""
                                placeholder="tracedSVG"
                                layout="fixed"
                                height={53}
                                />
                            {/* <GatsbyImage className="logo transition-opacity" image={logoData.file.childImageSharp.gatsbyImageData} alt={""} /> */}
                            {/* <LogoPurpleSVG className="logo" /> */}
                        </div>
                        
                        <div className="logo-name-container ">
                            <h1>{siteTitle}</h1>
                        </div>
                    </Link>
                </div>
                <div className="footer-description-container">
                    <p className="footer-description">
                        Todos los derechos reservados © 2022 &nbsp;<b>{siteTitle}</b>
                    </p>
                </div>
            </div>
       
        </div>

    </footer>
  );
};

export default FooterComponent;
