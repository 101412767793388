import React from 'react';
import { Helmet } from 'react-helmet';
import { SiteMetaDataInterface } from '../../interfaces/site.metadata.interface';

export const HeadComponent = (siteMetaData: SiteMetaDataInterface ) => {
  return (
    <Helmet
      htmlAttributes={{
        lang: siteMetaData.lang,
      }}
    >
      <meta charSet={siteMetaData.charSet} />
      <title>{siteMetaData.title}</title>
      {siteMetaData.description && (
        <meta
          name="description"
          property="og:description"
          content={siteMetaData.description}
        />
      )}
      <meta property="og:title" content={siteMetaData.title} />
      {siteMetaData.image && <meta property="og:image" content={siteMetaData.image} />}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={siteMetaData.title} />
      {siteMetaData.description && <meta name="twitter:description" content={siteMetaData.description} />}
      {siteMetaData.image && <meta name="twitter:image" content={siteMetaData.image} />}
    </Helmet>
  )
}
