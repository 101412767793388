import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
// import LogoWhiteSVG from "../../svg/logoWhiteSVG.svg";
// import LogoPurpleSVG from "../../svg/logoPurpleSVG.svg";
import "./header.component.scss";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { SiteMetaDataInterface } from "../../interfaces/site.metadata.interface";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";

export const HeaderComponent = (siteMetaData: SiteMetaDataInterface) => {
  
  // const logoData = useStaticQuery(
  //   graphql`
  //     query GetLogo {
  //       file(childImageSharp: {}, relativePath: {eq: "logo.png"}) {
  //         childImageSharp {
  //           gatsbyImageData(
  //             placeholder: BLURRED
  //             formats: WEBP
  //             height: 50
  //             pngOptions: {compressionSpeed: 53}
  //             transformOptions: {grayscale: true, fit: CONTAIN, cropFocus: CENTER}
  //           )
  //         }
  //       }
  //     }
  //   `
  // );
  
  return (
    <header className="header">
      <div className="header-container">
        <Link to="/" className="logo-container">
          <div className="logo-image">
              <StaticImage
                  className="transition-opacity"
                  src="../../assets/images/logo.png"
                  alt=""
                  placeholder="tracedSVG"
                  // layout="fixed"
                  height={53}
                />
              {/* <GatsbyImage className="logo transition-opacity" image={logoData.file.childImageSharp.gatsbyImageData} alt={""} /> */}
              {/* <LogoPurpleSVG className="logo" /> */}
          </div>
          
          <div className="logo-name-container ">
              <h1>{siteMetaData.title}</h1>
          </div>
        </Link>

        <div className="header-menu-container">

          <ul className="header-menu">
            {siteMetaData.menuLinks &&
              siteMetaData.menuLinks.map((node) => (
                
                <li key={node.name + '-li'} className="menu-option">
                  <AnchorLink key={node.name} to={node.link}>
                    {node.name}
                  </AnchorLink>
                </li>            
              ))}
          </ul>
        </div>
      </div>
    </header>
  );
};
