import React from 'react';
import { BackgroundComponent } from '../../components/background/background.component';
import FooterComponent from '../../components/footer/footer.component';
// import { FooterComponent } from '../../components/footer/footer.component';
import { HeadComponent } from '../../components/head/head.component';
import { HeaderComponent } from '../../components/header/header.component';
import { WhatsappButtonComponent } from '../../components/whatsapp-button/whatsapp-button.component';
 import "./main.layout.scss";

export const MainLayout = (props: any) => {  
  return (
    <>
    <div className='main-layout'>
      
      
      <BackgroundComponent/>
      
      <HeadComponent {...props.siteMetaData} />
      <HeaderComponent {...props.siteMetaData} />
      <div className='main-layout-body'>
        {props.children}
      </div>
      <WhatsappButtonComponent/>
      <FooterComponent siteTitle={props.siteMetaData.title} className={props.className}/>

      {/* <ul className="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul> */}
      
    
      
      
      {/* <ul className="circles-2">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul> */}
      
      
      </div>
    </>
  )
}